import moment from 'moment';

export function formatMoney(money: number | undefined | null, currency: string | null = 'GBP', omitCurrencySymbol = false) {

  if (currency === null || currency == "£") {
    currency = 'GBP';
  }

  if (money === undefined || money === null) {
    return '';
  }

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency,
  });

  if (omitCurrencySymbol) {
    return formatter.format(money).replace('£', '');
  }

  return formatter.format(money);
}

export function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function formatDatabaseTime(dateTime: string | undefined | null) {
  if (dateTime === undefined || dateTime === null) {
    return '';
  }
  const date = moment(dateTime, 'YYYY-MM-DD HH:mm:ss:SS');

  return date.format('ll');
}

const options = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

export function formatNum(value: number | undefined | null): string {
  if (value === undefined || value === null) {
    return '';
  }
  return Number(value).toLocaleString('en', options);
}