import bgImage from '../../images/login_backround.png';
import logo from '../../images/wc-dark-header.png';
import React, { ReactNode } from 'react';

type PropsWithChildren<P> = P & { children?: ReactNode };

function BlueBackgroundScreen({children, ...props}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  return (
    <div
      className="flex justify-center items-center min-h-screen fade-in bg-center bg-cover"
      style={{ backgroundImage: `url(${bgImage})` }}>
      <div {...props}
        className="min-h-full w-full max-w-4xl p-8 bg-white rounded-lg shadow-md text-center flex flex-col justify-center items-center">
        <img
          src={logo}
          className="lg:w-[328px] h-auto w-[75%] mb-8"
          alt=""></img>
        <div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default BlueBackgroundScreen;