import React from 'react';
import PageTitle from '../PageTitles/PageTitle';
import { useParams } from 'react-router-dom';
import { NonCustodyHolding } from '../../dto/Holding';
import UnderlyingLoader from '../Investments/SidePanelBlocks/investments/UnderlyingLoader';
import RelatedDocs from '../Investments/SidePanelBlocks/docs/RelatedDocs';
import { sortByInvestmentName, sortInvestmentsByPhaseAndInvDate } from '../../utils/SortUtils';

// Match the type you already have:
export type SideBarContent = {
  holding: NonCustodyHolding;
  openType: 'DOCS' | 'INVESTMENT';
} | null;

interface SideContentProps {
  content: SideBarContent;               // The "sideBar" you pass in
  data: NonCustodyHolding[] | undefined; // The "data" from your parent
}

const SideContent: React.FC<SideContentProps> = ({ content, data }) => {
  // 1. Always call hooks at the top level, unconditionally
  const { parent, accountId, cat, wrapper } = useParams();

  // 2. If there's no content or the data isn't loaded, just return null or a fallback
  if (!content || !data) {
    return null;
  }

  // 3. Helper function to produce "title text"
  const getTitleText = () => {
    if (parent) {
      return parent.replaceAll('_', ' ');
    }
    if (cat) {
      return cat.replaceAll('_', ' ');
    }
    return '';
  };

  // 4. Decide which block to render based on content.openType
  if (content.openType === 'INVESTMENT') {
    // Block A: INVESTMENT
    const { holding } = content;
    return (
      <div className="mt-10">
        <PageTitle
          title={'Investment Details'.toUpperCase()}
          subtitle={accountId ? getTitleText() : holding.investment.name}
        />
        <UnderlyingLoader data={data} holding={holding} />
      </div>
    );
  } else {
    // openType === 'DOCS' => Block B: DOCUMENTS
    const { holding } = content;

    // Sort holdings here
    const parentHoldings = data
      .filter((f) => f.parentHolding === holding.id)
      .sort(sortByInvestmentName);

    const sortedIDs: string[] = [];
    for (const ph of parentHoldings) {
      sortedIDs.push(ph.id);
      sortedIDs.push(
        ...data
          .filter((f) => f.parentHolding === ph.id)
          .sort(sortInvestmentsByPhaseAndInvDate)
          .map((h) => h.id)
      );
    }

    return (
      <div>
        <PageTitle
          title={'Important Documents'.toUpperCase()}
          subtitle={holding.investment.name}
        />
        <RelatedDocs holding={holding} sortedIDs={sortedIDs} />
      </div>
    );
  }
};

export default SideContent;
