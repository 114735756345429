import React, { useState } from 'react';
import { formatMoney } from '../../utils/FormattingUtils';

interface HeaderProps {
  /** Total value in GBP, as provided from the overview (might be 0) */
  value: number;
  /**
   * Object with currency codes as keys and total values as values.
   * For example: { GBP: 0, USD: 0, EUR: 1234 }
   */
  currencyTotals?: { [key: string]: number };
}

function PortfolioHeader({ value, currencyTotals = {} }: HeaderProps) {
  // Create a local copy so we don't mutate props directly
  const adjustedCurrencyTotals = { ...currencyTotals };

  // Check if currencyTotals is empty
  const hasCurrencyTotals = Object.keys(adjustedCurrencyTotals).length > 0;

  // Sum all currency totals from the breakdown
  const computedCurrencySum = Object.values(adjustedCurrencyTotals).reduce((acc, num) => acc + num, 0);

  // If the breakdown is incomplete, assume missing value is in GBP and adjust
  if (hasCurrencyTotals && computedCurrencySum !== value) {
    const missingValue = value - computedCurrencySum;
    if ('GBP' in adjustedCurrencyTotals) {
      adjustedCurrencyTotals['GBP'] += missingValue;
    } else {
      adjustedCurrencyTotals['GBP'] = missingValue;
    }
  }

  // Use the adjusted breakdown for display logic
  const hasAdjustedCurrencyTotals = Object.keys(adjustedCurrencyTotals).length > 0;
  const hasGbp = hasAdjustedCurrencyTotals && Object.prototype.hasOwnProperty.call(adjustedCurrencyTotals, 'GBP');
  const nonGbpCurrencies = hasAdjustedCurrencyTotals
    ? Object.entries(adjustedCurrencyTotals).filter(
        ([currency]) => currency !== 'GBP' && currency !== 'GBX'
      )
    : [];
  // Count GBP (if present) plus non‑GBP currencies
  const totalCurrencies = (hasGbp ? 1 : 0) + nonGbpCurrencies.length;

  // For fewer than 3 currencies, display them side-by-side
  if (!hasAdjustedCurrencyTotals || totalCurrencies < 3) {
    return (
      <div className="p-4 flex flex-row md:justify-between items-center bg-gray-100">
        <h3 className="text-lg font-semibold">My portfolio</h3>
        <div className="flex flex-row items-center space-x-8">
          {hasAdjustedCurrencyTotals ? (
            <>
              {hasGbp && (
                <div className="flex items-center">
                  <h3 className="text-base font-medium">Total value:</h3>
                  <h2 className="text-bond text-xl ml-2">
                    {formatMoney(adjustedCurrencyTotals['GBP'], 'GBP')}
                  </h2>
                </div>
              )}
              {nonGbpCurrencies.map(([currency, total]) => (
                <div key={currency} className="flex items-center">
                  <h3 className="text-base font-medium">Total value:</h3>
                  <h2 className="text-bond text-xl ml-2">
                    {formatMoney(total, currency)}
                  </h2>
                </div>
              ))}
            </>
          ) : (
            <div className="flex items-center">
              <h3 className="text-base font-medium">Total value:</h3>
              <h2 className="text-bond text-xl ml-2">
                {formatMoney(value, 'GBP')}
              </h2>
            </div>
          )}
        </div>
      </div>
    );
  }

  // For three or more currencies, display an accordion for non‑GBP currencies.
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="p-4 flex flex-row md:justify-between items-center bg-gray-100">
      <h3 className="text-lg font-semibold">My portfolio</h3>
      <div className="flex flex-row items-center space-x-8">
        {hasGbp && (
          <div className="flex items-center">
            <h3 className="text-base font-medium">Total value:</h3>
            <h2 className="text-bond text-xl ml-2">
              {formatMoney(adjustedCurrencyTotals['GBP'], 'GBP')}
            </h2>
          </div>
        )}
        {nonGbpCurrencies.length > 0 && (
          <div className="w-auto">
            <div
              className="p-2 cursor-pointer bg-gray-100 flex justify-between items-center border rounded"
              onClick={() => setAccordionOpen(!accordionOpen)}
            >
              <h3 className="text-base font-medium">Other currencies</h3>
              <span className="text-xl font-bold">
                {accordionOpen ? '-' : '+'}
              </span>
            </div>
            {accordionOpen && (
              <div className="mt-2 border rounded p-2 space-y-2">
                {nonGbpCurrencies.map(([currency, total]) => (
                  <div key={currency} className="flex justify-between">
                    <span className="font-medium">Total value:</span>
                    <span className="text-bond">
                      {formatMoney(total, currency)}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default PortfolioHeader;
