import React, { LabelHTMLAttributes } from 'react';



export interface RequirementProps {
  htmlFor: LabelHTMLAttributes<HTMLLabelElement>['htmlFor'];
  isValid: boolean;
  invalidMessage: string;
  validMessage: string;
}


const Requirement = (props: RequirementProps) => {
  return (
    <div>
      <label htmlFor={props.htmlFor} className={"flex flex-row gap-1"}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor"
               className={`w-5 ${props.isValid ? "text-midas" :"text-[#E0DEDA]"} `}>
            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
          </svg>

        <span className={`${props.isValid ? "text-[#68b346ee]" :"text-[#f75a5a]"}`}>{props.isValid ? props.validMessage : props.invalidMessage}</span>
      </label>
    </div>);
};


export default Requirement;