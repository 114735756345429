import React, { useState } from 'react';
import ReturnLink from '../components/ReturnLink/Return';
import { ReactComponent as DashboardLogo } from '../images/icons/icon-dashboard.svg';
import { ReactComponent as PortfolioLogo } from '../images/icons/icon-portfolio.svg';
import { ReactComponent as SecMesLogo } from '../images/icons/icon-secure-messages.svg';
import { ReactComponent as AppLogo } from '../images/icons/icon-doc-centre.svg';
import { ReactComponent as IconGear } from '../images/icons/icon-gear.svg';
import logo from '../images/wealth-club-portal-logo.svg';
import './nav.css';
import { ReactComponent as DropdownIcon } from '../images/icons/icon-chevron-down.svg';

import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../context/DependencyInjector';
import LogoutComponent from '../components/Logout/Logout';
import NavDropDownLinks from '../components/NavItem/NavDropDownLinks';
import LoadingBlock from '../components/Loading/LoadingBlock';
import useToken from '../hooks/useToken';

export interface ParentProps {
  parent: string;
}

export interface NavOpenObject {
  accountSettings: boolean;
  portfolio: boolean;
}

function SideNav(props: ParentProps) {
  const { secureMessageService, investmentService } = useDependencyInjector();

  const nav = useNavigate();

  const [open, setOpen] = useState<NavOpenObject>({
    accountSettings: false,
    portfolio: false,
  });

  const auth = useToken();

  const { data } = useQuery({
    queryKey: ['message-count'],
    queryFn: async () => {
      return await secureMessageService.getMessageCount();
    },
    enabled: auth.token.as === undefined,
    refetchInterval: 60000,
  });

  const { isLoading: navIsLoading, data: navData } = useQuery({
    queryKey: ['nav-data'],
    staleTime: Infinity,
    queryFn: async () => {
      return await investmentService.getNavItemsForInvestments();
    },
  });

  return (
    <div
      id="nav"
      className="h-[100vh] md:flex md:flex-col align-middle  hidden bg-right-bottom md:w-[325px] p-4"
      style={{ backgroundImage: `url(/images/blue-swirl-bg-rotated.jpg)` }}>
      <div className="w-full flex flex-col align-middle items-center mt-12">
        <div className="w-[80%]">
          <img src={logo} className="w-[328px] hidden lg:block" alt=""></img>
        </div>
      </div>

      <div className="flex flex-col gap-6 ml-10 mt-10">
        <div className={' font-semibold w-[100%] group'}>
          <div className={'flex flex-col gap-3'}>
            <div className={`flex flex-row justify-between gap-4`}>
              <div
                className={
                  'flex flex-row gap-2 align-middle justify-center items-center'
                }>
                <DashboardLogo
                  width={30}
                  height={30}
                  title="Secure messages"
                  className={'group-hover:fill-sand fill-white'}
                />
                <h6
                  className={
                    'font-semibold text-lg select-none hover:cursor-pointer whitespace-nowrap text-white group-hover:text-sand'
                  }
                  onClick={(event) => nav('/')}>
                  Dashboard
                </h6>
              </div>
            </div>
          </div>
        </div>

        {/*portfolio */}
        <div className={'text-white font-semibold w-[100%]'}>
          <div className={'flex flex-col gap-3'}>
            <div
              className={`flex flex-row justify-between gap-4 group-hover:fill-sand group`}
              onClick={(event) => {
                const currentState = open.portfolio;
                setOpen({ accountSettings: false, portfolio: !currentState });
              }}>
              <div
                className={
                  'flex flex-row gap-2 align-middle justify-center items-center'
                }>
                <PortfolioLogo
                  width={30}
                  height={30}
                  className={`group-hover:fill-sand ${
                    open.portfolio ? 'fill-sand' : 'fill-white'
                  }`}
                />
                <h6
                  className={`font-semibold select-none text-lg hover:cursor-pointer whitespace-nowrap group-hover:text-sand ${
                    open.portfolio ? 'text-sand' : 'text-white'
                  }`}>
                  My portfolio
                </h6>
              </div>
              <DropdownIcon
                title="Expand Section"
                width={30}
                height={30}
                className={`float-right duration-500  group-hover:fill-sand ${
                  open.portfolio
                    ? 'rotate-180 fill-sand'
                    : 'rotate-0 fill-white'
                }`}
              />
            </div>
            {navIsLoading && open.portfolio && <LoadingBlock />}

            <div
              className={`flex flex-col gap-2 text-left font-medium ${
                open.portfolio ? 'visible' : 'hidden'
              }`}>
              {navData &&
                navData.map((value, index) => {
                  return <NavDropDownLinks key={index} navItem={value} />;
                })}
            </div>
          </div>
        </div>

        {/*application history*/}
        <div className={' font-semibold w-[100%]  group'}>
          <div className={'flex flex-col gap-3'}>
            <div className={`flex flex-row justify-between gap-4`}>
              <div
                className={
                  'flex flex-row gap-2 align-middle justify-center items-center'
                }>
                <AppLogo
                  width={30}
                  height={30}
                  title="My applications"
                  className={'group-hover:fill-sand fill-white'}
                />
                <h6
                  className={
                    'font-semibold text-lg select-none hover:cursor-pointer whitespace-nowrap text-white group-hover:text-sand'
                  }
                  onClick={(event) => nav('/application-history')}>
                  My applications
                </h6>
              </div>
            </div>
          </div>
        </div>

        {/*Secure messages*/}
        <div className={' font-semibold w-[100%]  group'}>
          <div className={'flex flex-col gap-3'}>
            <div className={`flex flex-row justify-between gap-4`}>
              <div
                className={
                  'flex flex-row gap-2 align-middle justify-center items-center'
                }>
                <SecMesLogo
                  width={30}
                  height={30}
                  title="Secure messages"
                  className={'group-hover:fill-sand fill-white'}
                />
                <h6
                  className={
                    'font-semibold text-lg select-none hover:cursor-pointer whitespace-nowrap text-white group-hover:text-sand'
                  }
                  onClick={(event) => nav('/inbox')}>
                  Secure messages
                </h6>
                <div className="w-[25px] h-[25px] xl:block hidden">
                  <div
                    className={`rounded-full w-full h-full ml-1 -mt-4 bg-beret ${
                      auth.token.as != undefined ? 'hidden' : 'flex'
                    }  align-middle items-center justify-center`}>
                    <p className="text-white text-[0.7rem] font-bold z-10 pt-[2px] cursor-default select-none">
                      {data && data > 99 ? '99+' : data?.toString()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Account settings*/}
        <div className={'text-white font-semibold w-[100%]'}>
          <div
            className={'flex flex-col gap-3'}
            onClick={(event) => {
              const currentState = open.accountSettings;
              setOpen({ accountSettings: !currentState, portfolio: false });
            }}>
            <div
              className={`flex flex-row justify-between gap-4 group-hover:fill-sand group`}>
              <div
                className={
                  'flex flex-row gap-2 align-middle justify-center items-center'
                }>
                <IconGear
                  width={30}
                  height={30}
                  className={`group-hover:fill-sand ${
                    open.accountSettings ? 'fill-sand' : 'fill-white'
                  }`}
                />
                <h6
                  className={`font-semibold select-none text-lg hover:cursor-pointer whitespace-nowrap group-hover:text-sand ${
                    open.accountSettings ? 'text-sand' : 'text-white'
                  }`}>
                  Account settings
                </h6>
              </div>
              <DropdownIcon
                title="Expand Section"
                width={30}
                height={30}
                className={`float-right duration-500  group-hover:fill-sand ${
                  open.accountSettings
                    ? 'rotate-180 fill-sand'
                    : 'rotate-0 fill-white'
                }`}
              />
            </div>
            <div
              className={`flex flex-col gap-2 text-left font-medium ${
                open.accountSettings ? 'visible' : 'hidden'
              }`}>
              <Link to={'/update-contact-details'} className={'text-sm pl-10 '}>
                Contact details and preferences
              </Link>
              <Link to={'/commission-charges'} className={'text-sm pl-10'}>
                Commission and charges
              </Link>
              <Link to={'/nominated-bank-account'} className={'text-sm pl-10'}>
                Nominated bank account
              </Link>
              <Link to={'/rebate-bank-account'} className={'text-sm pl-10'}>
                VCT rebate bank account
              </Link>
              <Link to={'/change-password'} className={'text-sm pl-10 '}>
                Password and security
              </Link>
            </div>
          </div>
        </div>

        <hr className="border-1 border-white mt-6 w-[100%]"></hr>
        <ReturnLink />
        <div className="mt-4">
          <LogoutComponent />
        </div>
      </div>
    </div>
  );
}

export default SideNav;
