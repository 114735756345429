import api from './api';
import { AccountOverview } from '../dto/AccountOverview';
import { NonCustodyHolding } from '../dto/Holding';
import HoldingWithDocuments from '../dto/HoldingWithDocuments';
import { CursorWrapper } from '../dto/CursorWrapper';
import { HoldingHistory, HoldingHistoryCount } from '../dto/HoldingHistory';
import { NavItem, NavItemsDto } from '../dto/NavItemsDto';

interface IInvestmentService {
  getSubCatOverview(category: string): Promise<AccountOverview>;

  getInvestmentOverview(): Promise<AccountOverview>;

  getTotalInvested(accountOverview: AccountOverview): number;


  getHumanReadableNameForCategory(shortName: string): string;

  getUrlForCategory(shortName: string): string;

  getNonCustodyHoldings(parentCategory: string, subCategory: string): Promise<Array<NonCustodyHolding>>;

  getNumberOfChildren(id: string, holdings: NonCustodyHolding[]): number;

  totalUpHoldings(holdings: NonCustodyHolding[]): number;

  getTopLevelInvestments(holdings: NonCustodyHolding[]): Array<NonCustodyHolding>;

  getNonCustodyHoldingsDfm(accountId: string): Promise<Array<NonCustodyHolding>>;

  getDocumentsForInvestment(holdingId: string): Promise<Array<HoldingWithDocuments>>;

  getHoldingHistory(holdingId: string, cursor: number): Promise<CursorWrapper<HoldingHistory>>;

  getHoldingHistoryDfm(accountId: string, cursor: number): Promise<CursorWrapper<HoldingHistory>>;

  getHoldingHistoryCount(holdingId: string): Promise<HoldingHistoryCount>;

  getHoldingHistoryCountDfm(accountId: string): Promise<HoldingHistoryCount>;

  getNavItemsForInvestments(): Promise<Array<NavItemsDto>>;
}


class InvestmentService implements IInvestmentService {
  getDocumentsForInvestment(holdingId: string): Promise<HoldingWithDocuments[]> {
    return new Promise((resolve, reject) => {
      api.get(`/investments/${holdingId}/documents`).then(res => {
        const response = res.data as Array<HoldingWithDocuments>;
        resolve(response);
      }).catch(reason => {
        reject(reason);
      });
    });
  }

  getNonCustodyHoldingsDfm(accountId: string): Promise<NonCustodyHolding[]> {
    return new Promise((resolve, reject) => {
      api.get('/investments/dfm/' + accountId).then(res => {
        const response = res.data as Array<NonCustodyHolding>;
        resolve(response);
      }).catch(reason => {
        reject(reason);
      });
    });
  }

  getTopLevelInvestments(holdings: NonCustodyHolding[]): NonCustodyHolding[] {
    const results = holdings.filter(value => value.parentHolding == null);
    return results;
  }

  removeExpiredHoldings(holdings: NonCustodyHolding[]): NonCustodyHolding[] {
    return holdings.filter(value => value.units != 0 || value.value > 0);
  }

  totalUpHoldings(holdings: NonCustodyHolding[]): number {
    let total = 0.00;

    holdings.filter(value => value.valuationMode != 'sumUnderlyingValues' && (value.invDate != null ||  value.value > 0)).map(
      (value, index) => total += value.value,
    );
    return total;
  }

  totalUpCost(holdings: NonCustodyHolding[]): number {
    let totalCost = 0.0;
  
    // Collect IDs of all holdings that are referenced as a parent
    const childParentIds = new Set(
      holdings
        .filter(h => h.parentHolding !== null)
        .map(h => h.parentHolding),
    );
  
    // Sum cost of only those holdings that do *not* appear as a parent ID
    // (i.e., leaf nodes), are phase=20, and have a non-null cost
    holdings
      .filter(h =>
        !childParentIds.has(h.id) &&
        h.cost !== null &&
        h.phase === 20 &&
        h.units !== null &&
        h.units > 0 &&
        h.currency == 'GBP'
      )
      .forEach(h => {
        totalCost += h.cost!;
      });
  
    return totalCost;
  }


  getCashValue(holdings: NonCustodyHolding[]): number {
    const cashHolding = holdings.find((holding) => holding.investment.name === 'Cash')
    return cashHolding ? cashHolding.value : 0;
  }

  removeCashHolding(holdings: NonCustodyHolding[]) : NonCustodyHolding[] {
    return holdings.filter((holding) => holding.investment.name !== 'Cash')
  }


  getNumberOfChildren(id: string, holdings: NonCustodyHolding[]): number {
    if (id === null || id === undefined) {
      return 0;
    }
    const results = holdings.filter(value => value.parentHolding == id);
    return results.length;
  }


  getNonCustodyHoldings(parentCategory: string, subCategory: string): Promise<Array<NonCustodyHolding>> {
    return new Promise((resolve, reject) => {
      api.get('/investments/' + parentCategory + '/' + subCategory).then(res => {
        const response = res.data as Array<NonCustodyHolding>;
        resolve(response);
      }).catch(reason => {
        reject(reason);
      });
    });
  }


  getSubCatOverview(category: string): Promise<AccountOverview> {
    return new Promise((resolve, reject) => {
      api.get(`/investments/overview/${category}`).then(res => {
        const response = res.data as AccountOverview;
        resolve(response);
      }).catch(reason => {
        reject(reason);
      });
    });
  }

  getUrlForCategory(shortName: string): string {
    switch (shortName) {
      case 'SOPHISTICATED_TAX_INVESTMENTS':
        return '/tax-efficient-investments';
      case 'DFM':
        return '/managed-portfolios';
      case 'EIS_SINGLE_COMPANY':
        return '/single-company-investments';
      case 'PRIVATE_MARKET_AND_REAL_ASSETS':
        return '/private-markets-real-estate-assets';
      default:
        return '/other-investments';
    }
  }

  getHumanReadableNameForCategory(shortName: string): string {
    switch (shortName) {
      case 'SOPHISTICATED_TAX_INVESTMENTS':
        return 'Tax Efficient Investments';
      case 'DFM':
        return 'Managed Portfolios';
      case 'EIS_SINGLE_COMPANY':
        return 'Single Company Investments';
      case 'PRIVATE_MARKET_AND_REAL_ASSETS':
        return 'Private Market and Real Assets';
      default:
        return 'Other Investments';
    }
  }

  getInvestmentOverview(): Promise<AccountOverview> {
    return new Promise((resolve, reject) => {
      api.get('/investments/overview').then(res => {
        const response = res.data as AccountOverview;
        resolve(response);
      }).catch(reason => {
        reject(reason);
      });
    });
  }


  getTotalInvested(accountOverview: AccountOverview): number {
    let total = 0;
    accountOverview.overview.forEach(value => {
      total = total + value.totalValue;
    });
    return total;
  }


  getHoldingHistory(holdingId: string, cursor: number): Promise<CursorWrapper<HoldingHistory>> {
    return new Promise((resolve, reject) => {
      api.get(`/investments/${holdingId}/history?cursor=${cursor}`).then(res => {
        const response = res.data as CursorWrapper<HoldingHistory>;
        resolve(response);
      }).catch(reason => {
        reject(reason);
      });
    });
  }

  getHoldingHistoryCount(holdingId: string): Promise<HoldingHistoryCount> {
    return new Promise((resolve, reject) => {
      api.get(`/investments/${holdingId}/history/count`).then(res => {
        const response = res.data as HoldingHistoryCount;
        resolve(response);
      }).catch(reason => {
        reject(reason);
      });
    });
  }

  getNavItemsForInvestments(): Promise<Array<NavItemsDto>> {
    return new Promise((resolve, reject) => {
      api.get(`/investments`).then(res => {
        const response = res.data as Array<NavItemsDto>;
        resolve(response);
      }).catch(reason => {
        reject(reason);
      });
    });
  }

  getHoldingHistoryDfm(accountId: string, cursor: number): Promise<CursorWrapper<HoldingHistory>> {
    return new Promise((resolve, reject) => {
      api.get(`/investments/dfm/${accountId}/history?cursor=${cursor}`).then(res => {
        const response = res.data as CursorWrapper<HoldingHistory>;
        resolve(response);
      }).catch(reason => {
        reject(reason);
      });
    });
  }

  getHoldingHistoryCountDfm(accountId: string): Promise<HoldingHistoryCount> {
    return new Promise((resolve, reject) => {
      api.get(`/investments/dfm/${accountId}/history/count`).then(res => {
        const response = res.data as HoldingHistoryCount;
        resolve(response);
      }).catch(reason => {
        reject(reason);
      });
    });
  }


}

export default InvestmentService;