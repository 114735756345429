import React, { useState } from 'react';
import ContentComponent from '../components/ContentContainer/Content';
import NotificationBanner from '../components/Notification/Notification';
import useToken from '../hooks/useToken';
import CTABox from '../components/CallToAction/CTABox';
import DashboardPortfolio from '../components/PageSection/DashboardPortfolio';
import { useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../context/DependencyInjector';
import { CtaLocation } from '../services/CtaService';
import { AxiosError } from 'axios';
import WarningMessage from "../components/Warning/WarningMessage";
import {DEFAULT_WARN_MSG} from "../utils/Constants";

function DashboardPage() {
  const { token } = useToken();
  const { clientService } = useDependencyInjector();
  const [notification, setNotification] = useState<boolean>(false);

  const { data } = useQuery({
    queryKey: ['client-num'],
    queryFn:
      async () => {
        return await clientService.getClientNum();
      },
  });

  const { ctaService } = useDependencyInjector();


  const { data: ctaOne } = useQuery({
    queryKey: ['dashboardOneCta'],
    staleTime:Infinity,
    queryFn:
      async () => {
        try {
          return await ctaService.getCta(CtaLocation.DASHBOARD_ONE);
        } catch (error) {
          if (error instanceof AxiosError) {
            if (error.response?.status === 404) {
              return undefined;
            }
          }
          throw error;
        }
      },
  });

  const { data: ctaTwo } = useQuery({
    queryKey: ['dashboardTwoCta'],
    staleTime:Infinity,
    queryFn:
      async () => {
        try {
          return await ctaService.getCta(CtaLocation.DASHBOARD_TWO);
        } catch (error) {
          if (error instanceof AxiosError) {
            if (error.response?.status === 404) {
              return undefined;
            }
          }
          throw error;
        }
      },
  });


  return (
    <div>
      <ContentComponent
        title="Dashboard"
        jsxSubTitle={
          <div className="w-full flex flex-row align-middle gap-2">
            <h6 className="text-lg font-bold">
              Welcome {token?.user.fullName},
            </h6>
            {data && (
              <h6 className="text-sm pt-1.5 text-midas">
                <span className="text-bond">Client no. </span>
                {data}
              </h6>
            )}
          </div>
        }
        subtitle={'Welcome ' + token?.user.fullName}>
        <div className="w-[100%] h-fit mb-12">
          <NotificationBanner
            notification={notification}
            setNotification={setNotification}
          />

          <DashboardPortfolio />

          <WarningMessage message={DEFAULT_WARN_MSG} />


          <div className="w-full mt-10 md:mt-[3%]">

            <div className="flex flex-col md:grid md:grid-cols-2 md:gap-5 ">

              {ctaOne != undefined &&
                <CTABox
                  title={ctaOne.header}
                  subtitle={ctaOne.body}
                  buttonText={ctaOne.buttonLabel}
                  onclick={() => window.location.href = ctaOne.buttonUrl}
                />
              }

              {ctaTwo != undefined &&
                <CTABox
                  title={ctaTwo.header}
                  subtitle={ctaTwo.body}
                  buttonText={ctaTwo.buttonLabel}
                  onclick={() => window.location.href = ctaTwo.buttonUrl}
                />
              }
            </div>
          </div>
        </div>
      </ContentComponent>
    </div>
  );
}

export default DashboardPage;
