import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { NonCustodyHolding } from '../../dto/Holding';
import { useQuery } from '@tanstack/react-query';
import { useDependencyInjector } from '../../context/DependencyInjector';
import useWindowSize from '../../hooks/useWindowSize';
import SidePanel from '../../components/Investments/SidePanel';
import SideContent from '../../components/SideContent/SideContent';
import ContentComponent from '../../components/ContentContainer/Content';
import LoadingBlock from '../../components/Loading/LoadingBlock';
import TableSort from '../../components/Investments/InvestmentTableSort';
import TrendingOffersBox from '../../components/PageSection/TrendingOffersBox';
import PaginationButtons from '../../components/Pagination/Pagination';
import DrilldownTable from '../../components/Investments/DrilldownTable';
import DrilldownValue from '../../components/Investments/DrilldownValue';
import MobileInvestmentBlock from './MobileInvestmentBlock';
import ErrorBanner from '../../components/Error/ErrorBanner';
import WarningMessage from '../../components/Warning/WarningMessage';
import { DEFAULT_WARN_MSG } from '../../utils/Constants';
import AimIhtWarning from '../../components/Warning/AimIhtWarning';
import { PhaseEnum } from '../../constants/Constants';

type SortValues = 'investment' | 'value' | 'growth' | null;

export type SideBarContent = {
  holding: NonCustodyHolding;
  openType: 'DOCS' | 'INVESTMENT';
} | null;

const InvestmentDrillDown = () => {
  const { parent, child, wrapper, accountId, cat } = useParams();
  const [pager, setPager] = useState(1);
  const [sideBar, setSideBar] = useState<SideBarContent>(null);
  const [desc, setDesc] = useState<boolean>(true);
  const [sort, setSort] = useState<SortValues>('investment');
  const { width } = useWindowSize();

  const { investmentService } = useDependencyInjector();

  const updatePage = (page: number) => {
    setPager(page);
  };

  const closeSideBar = () => {
    setSideBar(null);
  };

  const { isLoading, isError, data } = useQuery({
    queryKey: ['holdings', parent, child, wrapper, accountId],
    queryFn: async () => {
      if (wrapper && accountId) {
        return await investmentService.getNonCustodyHoldingsDfm(accountId);
      }
      return await investmentService.getNonCustodyHoldings(parent!, child!);
    },
    enabled:
      (parent !== undefined && child !== undefined) ||
      (wrapper !== undefined && accountId !== undefined),
  });

  const getTitleText = () => {
    if (parent) {
      return parent.replaceAll('_', ' ');
    }
    if (cat) {
      return cat.replaceAll('_', ' ');
    }
    return '';
  };

  const getSubtitleText = () => {
    if (child) {
      return child.replaceAll('_', ' ');
    }
    if (wrapper) {
      return wrapper.replaceAll('_', ' ');
    }
    return '';
  };

  // Compute value totals by currency
  const currencyTotals = useMemo(() => {
    const totals = new Map<string, number>();
    if (data) {
      data.forEach((holding) => {
        if (holding.currency) {
          const currentTotal = totals.get(holding.currency) || 0;
          totals.set(holding.currency, currentTotal + holding.value);
        }
      });
    }
    return totals;
  }, [data]);

  // Compute total GBP value
  // Compute the subset of holdings that are in GBP
const gbpHoldings = useMemo(() => {
  return data ? data.filter(holding => holding.currency === 'GBP') : [];
}, [data]);

// Now compute the total GBP value using the investmentService helper
const totalGBPValue = investmentService.totalUpHoldings(gbpHoldings);

  // Check if there are non-GBP holdings
  const nonGbpHoldingsExist = useMemo(() => {
    return Array.from(currencyTotals.keys()).some((currency) => currency !== 'GBP');
  }, [currencyTotals]);

  // Compute cashValue
  const cashValue = useMemo(() => {
    if (data) {
      return investmentService.getCashValue(data);
    }
    return 0;
  }, [data, investmentService]);

  // Compute non‑GBP cost breakdown
const nonGbpCost = useMemo(() => {
  const costMap = new Map<string, number>();
  if (data) {
    // Get all holdings that are referenced as a parent
    const childParentIds = new Set(
      data.filter(h => h.parentHolding !== null).map(h => h.parentHolding)
    );
    data.forEach(h => {
      if (
        h.currency !== 'GBP' &&
        !childParentIds.has(h.id) &&
        h.cost !== null &&
        h.phase === PhaseEnum.Active &&
        h.units !== null &&
        h.units > 0
      ) {
        const current = costMap.get(h.currency) || 0;
        costMap.set(h.currency, current + h.cost);
      }
    });
  }
  return costMap;
}, [data]);

  // Compute filteredInvestments
  const filteredInvestments = useMemo(() => {
    if (data) {
      const nonCashInvestments = investmentService.removeCashHolding(data);
      const nonExpiredHoldings = investmentService.removeExpiredHoldings(nonCashInvestments);
      const topLevelInvestments = investmentService.getTopLevelInvestments(nonExpiredHoldings);
      return topLevelInvestments;
    }
    return [];
  }, [data, investmentService]);

  // Compute childrenInvestments
  const childrenInvestments = useMemo(() => {
    if (data) {
      return data.filter(
        (value) =>
          value.parentHolding !== undefined && (value.invDate !== null || value.value > 0)
      );
    }
    return [];
  }, [data]);

  // Memoized sorted investments
  const sortedInvestments = useMemo(() => {
    if (filteredInvestments.length === 0) return [];

    const sortedData = [...filteredInvestments];

    const sortingFunc = (a: any, b: any) => {
      if (a > b) {
        return desc ? 1 : -1;
      }
      if (a < b) {
        return desc ? -1 : 1;
      }
      return 0;
    };

    const alphaSort = (a: NonCustodyHolding, b: NonCustodyHolding) => {
      return sortingFunc(
        a.investment.name.toLowerCase(),
        b.investment.name.toLowerCase(),
      );
    };

    const growthSort = (a: NonCustodyHolding, b: NonCustodyHolding) => {
      return sortingFunc(a.growthPercentage, b.growthPercentage);
    };

    const valueSort = (a: NonCustodyHolding, b: NonCustodyHolding) => {
      return sortingFunc(a.value, b.value);
    };

    switch (sort) {
      case 'investment':
        sortedData.sort(alphaSort);
        break;
      case 'value':
        sortedData.sort(valueSort);
        break;
      case 'growth':
        sortedData.sort(growthSort);
        break;
      default:
        break;
    }

    return sortedData;
  }, [filteredInvestments, sort, desc]);

  // Memoized paginated investments
  const MAX_PAGE_SIZE = 15;
  const numberOfPages = useMemo(() => {
    if (sortedInvestments.length === 0) return 1;
    return Math.ceil(sortedInvestments.length / MAX_PAGE_SIZE);
  }, [sortedInvestments]);

  const paginatedInvestments = useMemo(() => {
    if (sortedInvestments.length === 0) return [];
    const currentPage = Math.min(Math.max(pager, 1), numberOfPages);
    const startIndex = (currentPage - 1) * MAX_PAGE_SIZE;
    const endIndex = currentPage * MAX_PAGE_SIZE;
    return sortedInvestments.slice(startIndex, endIndex);
  }, [sortedInvestments, pager, numberOfPages]);

  if (isLoading) {
    return (
      <ContentComponent
        title={width > 700 ? 'My portfolio' : getTitleText()}
        subtitle={getSubtitleText()}
        secondaryTitle={width > 700 ? getTitleText() : ''}
      >
        <LoadingBlock />
        <WarningMessage
          message={
            child !== 'AIM_IHT_portfolios' ? DEFAULT_WARN_MSG : <AimIhtWarning />
          }
        />
        <TrendingOffersBox />
      </ContentComponent>
    );
  }

  if (isError && parent) {
    return (
      <ContentComponent
        title={width > 700 ? 'My portfolio' : getTitleText()}
        subtitle={getSubtitleText()}
        secondaryTitle={width > 700 ? getTitleText() : ''}
      >
        <ErrorBanner
          text={'An error occurred while loading holdings. Please try again.'}
          severe={true}
        />
        <TrendingOffersBox />
      </ContentComponent>
    );
  }

  return (
    <div>
      <SidePanel open={sideBar != null} close={closeSideBar}>
        <SideContent content={sideBar} data={data} />
      </SidePanel>
      <ContentComponent
        title={width > 700 ? 'My portfolio' : parent || wrapper ? getTitleText() : ''}
        subtitle={getSubtitleText()}
        secondaryTitle={width > 700 ? getTitleText() : ''}
      >
        <div className="w-[100%] h-fit mb-12">
          {data && !isLoading && (
            <>
              <DrilldownValue
                totalGBPValue={totalGBPValue}
                currencyTotals={nonGbpHoldingsExist ? currencyTotals : new Map()}
                nonGbpCost={nonGbpCost}
                isDfm={!!accountId}
                setSideBar={setSideBar}
                cashValue={cashValue}
                totalCost={investmentService.totalUpCost(data)}
              />
              <TableSort setSort={setSort} sort={sort} setDesc={setDesc} desc={desc} />
              <DrilldownTable
                nonCustodyHoldings={paginatedInvestments}
                childrenCustodyHoldings={childrenInvestments}
                setSideBar={setSideBar}
                isDfm={!!accountId}
              />
              <PaginationButtons
                pageCount={numberOfPages}
                onPageChange={updatePage}
              />
            </>
          )}
          <TrendingOffersBox />
        </div>
      </ContentComponent>
    </div>
  );
};

export default InvestmentDrillDown;
