import React, { useState } from 'react';
import { formatMoney } from '../../utils/FormattingUtils';
import { SideBarContent } from '../../pages/InvestmentDetails/InvestmentDrillDown';
import { NonCustodyHolding } from '../../dto/Holding';

interface ValueProps {
  /** Total GBP value */
  totalGBPValue: number;
  /** Total GBP cost */
  totalCost: number;
  /** Cash balance (applies to the GBP row) */
  cashValue: number;
  /**
   * Map of currency codes to total value. For example, if there are GBP, USD,
   * and EUR holdings, this map might contain keys like "GBP", "USD", "EUR", etc.
   */
  currencyTotals: Map<string, number>;
  /**
   * Map of non‑GBP currencies to total cost. (The GBP cost is passed separately.)
   */
  nonGbpCost: Map<string, number>;
  isDfm: boolean;
  setSideBar: (value: React.SetStateAction<SideBarContent>) => void;
}

function DrilldownValue(props: ValueProps) {
    // Get non-GBP currencies from the totals map
  const nonGbpCurrencies = Array.from(props.currencyTotals.entries()).filter(
    ([currency]) => currency !== 'GBP'
  );
    // If GBP total cost is 0 and there is only one non‑GBP currency,
  // start the accordion open.
  const shouldAccordionOpenInitially =
    props.totalCost === 0 && nonGbpCurrencies.length === 1;
  const [accordionOpen, setAccordionOpen] = useState(shouldAccordionOpenInitially);

  // Use a different header title if there is only one non‑GBP currency in category.
  const accordionHeaderTitle =
    props.totalCost === 0 && nonGbpCurrencies.length === 1
      ? "Summary"
      : 'Other currencies';



  return (
    <div className="space-y-4">
      {/* --- GBP Summary --- */}
      {props.totalCost > 0 && (
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 p-4 border rounded shadow-sm">
          <div className="flex flex-col md:flex-row items-center">
            <div className="flex items-center">
              <h3 className="text-lg font-bold">Total value:</h3>
              <h3 className="text-bond text-2xl ml-2" data-cy="totalValue-GBP">
                {formatMoney(props.totalGBPValue)}
              </h3>
            </div>

            {props.cashValue > 0 && (
              <div className="flex items-center ml-4">
                <h5 className="text-sm text-gray-600" data-cy="cashValue-GBP">
                  ({formatMoney(props.cashValue)} in cash balance)
                </h5>
              </div>
            )}

              <div className="flex items-center ml-4">
                <h3 className="text-lg font-bold">Amount invested:</h3>
                <h3 className="text-bond text-2xl ml-2" data-cy="totalCost-GBP">
                  {formatMoney(props.totalCost)}
                </h3>
              </div>
          </div>

          {props.isDfm && (
            <div className="mt-3 md:mt-0">
              <h5
                className="underline text-bond cursor-pointer"
                onClick={() =>
                  props.setSideBar({
                    holding: {} as NonCustodyHolding,
                    openType: 'INVESTMENT',
                  })
                }
              >
                View details
              </h5>
            </div>
          )}
        </div>
      )}

      {/* --- Accordion for Other Currencies --- */}
      {nonGbpCurrencies.length > 0 && (
        <div className="border rounded shadow-sm">
          <div
            className="p-4 cursor-pointer bg-gray-100 flex justify-between items-center"
            onClick={() => setAccordionOpen(!accordionOpen)}
          >
            <h3 className="text-lg font-bold">{accordionHeaderTitle}</h3>
            <span className="text-2xl font-bold">{accordionOpen ? '-' : '+'}</span>
          </div>
          {accordionOpen && (
            <div className="p-4 space-y-2">
              <div className="grid gap-y-3">
                {nonGbpCurrencies.map(([currency, totalValue]) => (
                  <div
                    key={currency}
                    className="grid grid-cols-[50px_minmax(120px,300px)_minmax(120px,300px)] gap-x-3 items-center"
                  >
                    {/* Currency Label - Smallest Column */}
                    <div className="font-bold text-lg text-left w-[50px]">({currency})</div>

                    {/* Total Value (Max 300px) */}
                    <div className="flex items-center w-[300px]">
                      <h3 className="text-lg font-bold">Total value:</h3>
                      <h3 className="text-bond text-2xl ml-2" data-cy={`totalValue-${currency}`}>
                        {formatMoney(totalValue, currency)}
                      </h3>
                    </div>

                    {/* Amount Invested (Max 300px) */}
                    {props.nonGbpCost.has(currency) && (
                      <div className="flex items-center w-[300px]">
                        <h3 className="text-lg font-bold">Amount invested:</h3>
                        <h3 className="text-bond text-2xl ml-2" data-cy={`totalCost-${currency}`}>
                          {formatMoney(props.nonGbpCost.get(currency)!, currency)}
                        </h3>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DrilldownValue;
